import { motion } from "framer-motion"

const CultureText = ({ initial, name }) => {
    return (
        <div className="w-full flex items-center justify-center uppercase font-semibold relative">
            <motion.div
                initial={{ opacity: 0, scale: 0, rotate: 200 }}
                whileInView={{ opacity: 1, scale: 1, rotate: 0 }}
                transition={{ duration: 1 }}
                className="w-16 aspect-square rounded-full bg-black text-white z-10 flex items-center justify-center text-4xl">
                {initial}
            </motion.div>
            <motion.div
                initial={{ opacity: 0, scale: 0, x: -200 }}
                whileInView={{ opacity: 1, scale: 1, x: 0 }}
                transition={{ duration: 1, delay: 1 }}
                className="h-10 w-full md:w-96 bg-red-700 text-white rounded-r-full flex items-center -ml-5 pl-6 tracking-widest">
                {name}
            </motion.div>
        </div>
    )
}

export default CultureText