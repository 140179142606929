import { motion } from "framer-motion"

const CardTeam = ({ name, position, image, waves, key }) => {
    return (
        <motion.div
            initial={{ opacity: 0, y:200  }}
            transition={{duration:1, delayChildren:0.3, staggerChildren:0.3 }}
            whileInView={{ opacity: 1, y:0 }}
            key={key}
            className="w-[350px] h-[450px] drop-shadow-sm shadow-md rounded-xl border relative overflow-hidden m-2">
            {image === '' || image === null || waves === undefined ?
                <div className="w-full "></div> :
                <img src={image} alt="Foto Owner Cokusi" className="w-full" />
            }
            <div className="w-full absolute bottom-0">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path
                        className="fill-white"
                        fill-opacity="1"
                        d={waves === '' || waves === null || waves === undefined ? 'M0,192L30,186.7C60,181,120,171,180,154.7C240,139,300,117,360,128C420,139,480,181,540,186.7C600,192,660,160,720,133.3C780,107,840,85,900,64C960,43,1020,21,1080,37.3C1140,53,1200,107,1260,122.7C1320,139,1380,117,1410,106.7L1440,96L1440,320L1410,320C1380,320,1320,320,1260,320C1200,320,1140,320,1080,320C1020,320,960,320,900,320C840,320,780,320,720,320C660,320,600,320,540,320C480,320,420,320,360,320C300,320,240,320,180,320C120,320,60,320,30,320L0,320Z' : waves}>    ``
                    </path>
                </svg>
                <div className="bg-white p-3 text-center text-red-700 font-semibold">
                    <p className="uppercase text-xl">{position}</p>
                    <p>{name}</p>
                </div>
            </div>
        </motion.div>
    )
}

export default CardTeam

























