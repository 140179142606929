import Logo from '../assets/Images/Logo.png'
const Footer = () => {
    return (
        <div id='footer' className="h-fit w-full bg-white drop-shadow-md shadow-md border pt-5">
            <div className='w-full flex flex-col lg:flex-row lg:space-x-5 space-y-5 py-5 px-3 md:px-5'>
                <div className='w-full lg:w-[25%] flex  items-center justify-center'>
                    <img src={Logo} alt="Logo Cokusi" className='w-[60%] md:w-[50%] aspect-square' />
                </div>
                <div className='flex flex-col md:flex-row w-full lg:w-[50%] md:space-x-5'>
                    <div className='w-full md:w-1/2 space-y-3'>
                        <p className='text-center font-semibold border-b-2 pb-3 border-black'>Our Service</p>
                        <ul>
                            <li className='list-inside list-disc'></li>
                            <li className='list-inside list-disc'></li>
                            <li className='list-inside list-disc'></li>
                            <li className='list-inside list-disc'></li>
                        </ul>
                    </div>
                    <div className='w-full md:w-1/2 space-y-3'>
                        <p className='text-center font-semibold border-b-2 pb-3 border-black'>Address</p>
                        <p className='text-justify'>
                            Perumahan PEPABRI Batulawang No. 183 Blok E RT. 05 RW. 09 Desa Talagasari Kecamatan Kadungora Kabupaten Garut, Jawa Barat, Indonesia Kode Pos. 44153
                        </p>

                    </div>
                </div>
                <div className='w-full lg:w-[25%]'>
                    <iframe className='w-full aspect-video rounded-md' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253318.00364823014!2d107.76943894844214!3d-7.230140627754527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e68b7c106d54461%3A0xf6f34934af21b4da!2sPerum%20Pepabri%20Batulawang!5e0!3m2!1sid!2sid!4v1713413382999!5m2!1sid!2sid" title='map lokasi cokusi' allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
            <p className='text-center font-semibold py-3 bg-red-500 text-white'>&copy; Cokusi Kameumeut</p>
        </div>
    )
}

export default Footer