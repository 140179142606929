import CultureText from "../../../components/culture_text"
import TitleSection from "../../../components/title_section"
import Image1 from '../../../assets/Images/img1.jpg'
import { motion } from 'framer-motion'


const OurCultureSection = () => {
    return (
        <div id="ourService" className="w-full bg-white py-20 px-3 md:px-5 lg:px-10">
            <TitleSection title="Our Culture" subTitle="Lorem ipsum dolor sit amet, consectetur adipisicing elit." />
            <div className="flex flex-col lg:flex-row items-center justify-center lg:pt-5">
                <div className="w-full lg:w-1/2 flex flex-col items-center justify-center space-y-3 order-2 lg:order-1">
                    <CultureText initial="c" name="heerful" />
                    <CultureText initial="o" name="ptimistic" />
                    <CultureText initial="k" name="ind" />
                    <CultureText initial="u" name="nique" />
                    <CultureText initial="s" name="ynergy" />
                    <CultureText initial="i" name="ntegriti" />
                </div>
                <div className="w-full lg:w-1/2 order-1 lg:order-2 my-5 lg:my-0">
                    <motion.img
                        initial={{ scale: 0, opacity: 0 }}
                        whileInView={{ scale: 1, opacity: 1 }}
                        transition={{ duration: 2 }}
                        src={Image1} alt="assets Cokusi" className="w-full  md:h-[420px]  mx-auto rounded-3xl" />
                </div>
            </div>
        </div>
    )
}

export default OurCultureSection