import TitleSection from "../../../components/title_section"
import Image2 from '../../../assets/Images/img2.jpg'
import { motion } from 'framer-motion';

const ProjectSummerySection = () => {
    return (
        <div id="projectSummery" className="w-full h-fit md:h-screen bg-red-700 pt-20 pb-10 md:pb-0 bg-fixed">
            <TitleSection title="Project Summery" color="white" subTitle="Lorem ipsum dolor sit amet, consectetur adipisicing elit." />
            <div className="w-full px-3 md:px-5 lg:px-10 pt-5 flex flex-col lg:flex-row items-center md:space-x-5">
                <div className="space-y-5 order-2 lg:order-1 text-xs md:text-sm lg:text-base pb-20 md:pb-0">
                    <p className="text-white text-center">
                        Cokusi kameumeut is a food made from dates filled with fruit dodol, covered in soft chocolate and sprinkled with delicious grilled cheese. Cokusi Kameumeut, one of the product brands from CV.Dzikra Indonesia, is a best seller because its products are unique and have their own characteristics compared to other competitors.
                    </p>
                    <p className="text-white text-center">
                        One of the advantages of this product is that it has a unique taste that comes from sour fruit dodol blending with the sweetness of dates covered in chocolate. and sprinkled with savory cheese. Cokusi kameumeut is very suitable for consumption as a snack which is served with hot tea or coffee and also with warm milk, so that our product can reach all people and ages. partnering with many gift shops and hotels as well as modern markets. cokusi kameumeut has also started to be well known and interested in many people outside the territory of Indonesia
                    </p>
                </div>
                <motion.img 
                initial={{opacity:0, scale:0, rotate:260}}
                whileInView={{opacity:1, scale:1, rotate:0}}
                transition={{duration:1}}
                 src={Image2} alt="asset cokusi" className="w-52 md:w-[500px] lg:w-96  aspect-square rounded-2xl order-1 lg:order-2 mb-5 md:my-10 lg:my-0 lg:mb-0" />
            </div>
        </div>
    )
}

export default ProjectSummerySection





































