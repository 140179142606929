import Footer from "../../components/footer"
import Navbar from "../../components/navbar"
import AboutSection from "./sections/about_section"
import BusinesModel from "./sections/business model"
import OurCultureSection from "./sections/our_culture"
import OurTeamSection from "./sections/our_team_section"
import ProjectSummerySection from "./sections/project_summery"
import VisiMisiSection from "./sections/visi_misi_section"
import WelcomeSection from "./sections/welcome_section"

const LandingPage = () => {
    return (
        <>
            <div className="w-full h-screen bg-red-500 overflow-auto text-black">
                <Navbar></Navbar>
                <WelcomeSection></WelcomeSection>
                <VisiMisiSection></VisiMisiSection>
                <AboutSection></AboutSection>
                <ProjectSummerySection></ProjectSummerySection>
                <OurTeamSection></OurTeamSection>
                <BusinesModel></BusinesModel>
                <OurCultureSection></OurCultureSection>

                <Footer></Footer>
            </div>
        </>
    )
}

export default LandingPage