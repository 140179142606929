import TitleSection from "../../../components/title_section"
import Owner from '../../../assets/Images/owner.png'
import Director from '../../../assets/Images/director.png'
import Manager from '../../../assets/Images/manager.png'
import CardTeam from "../../../components/card_team"


const OurTeamSection = () => {
    return (
        <div id="ourTeam" className=" h-fit w-full bg-white py-20 px-5 md:px-10 -mt-16">
            <TitleSection title="Our Super Team" subTitle="Lorem ipsum dolor sit amet, consectetur adipisicing elit." />
            <div className="flex items-center justify-center flex-wrap pt-5">
                <CardTeam
                    key={0}
                    name="RISA KRISTALIA NURLAELA, ST.,MT."
                    position="Owner"
                    image={Owner}
                    waves="M0,128L40,128C80,128,160,128,240,160C320,192,400,256,480,250.7C560,245,640,171,720,133.3C800,96,880,96,960,128C1040,160,1120,224,1200,224C1280,224,1360,160,1400,128L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z" />

                <CardTeam
                    name="HAFID DZULKHAIDIR"
                    position="Director"
                    image={Director}
                    waves="M0,192L40,186.7C80,181,160,171,240,160C320,149,400,139,480,160C560,181,640,235,720,261.3C800,288,880,288,960,261.3C1040,235,1120,181,1200,181.3C1280,181,1360,235,1400,261.3L1440,288L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z" />

                <CardTeam
                    name="WULAN NUR APRILLIA"
                    position="Manager"
                    image={Manager}
                    waves="M0,160L40,170.7C80,181,160,203,240,181.3C320,160,400,96,480,96C560,96,640,160,720,181.3C800,203,880,181,960,154.7C1040,128,1120,96,1200,85.3C1280,75,1360,85,1400,90.7L1440,96L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z" />
            </div>
        </div>
    )
}

export default OurTeamSection