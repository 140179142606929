import TitleSection from "../../../components/title_section"

const VisiMisiSection = () => {
    return (
        <div id="visiMisi" className=" lg:h-screen w-full bg-red-700 py-20 text-white">
            <TitleSection title="Visi & Misi" subTitle="Lorem ipsum dolor sit amet, consectetur adipisicing elit." />
            <div className="w-full text-center pt-10">
                <p className="w-fit text-2xl font-semibold border-b-2 mx-auto border-white">Visi</p>
                <p className="px-3 md:px-5 lg:px-52 py-5 text-xs md:text-sm lg:text-base">
                    To become a company that operates in the field of food processing, which is well-known in Indonesia, especially and globally, based on the Blessings Business foundation which prioritizes premium quality, unique and authentic products.

                </p>
            </div>
            <div className="w-full text-justify md:text-center px-5 lg:px-10">
                <p className="w-fit text-2xl font-semibold border-b-2 mx-auto border-white">Misi</p>
                <ul className="py-3 text-xs md:text-sm lg:text-base space-y-2">
                    <li className="list-inside list-disc">
                        Providing premium quality and guaranteed quality for every food product as well as friendly service to customers
                    </li>
                    <li className="list-inside list-disc ">
                        Building a company that is synergistic, creative and innovative and remains on a blessed business foundation
                    </li>
                    <li className="list-inside list-disc ">
                        Continue to move forward and provide the best results for the company and increase sales turnover for the company and partners at each outlet
                    </li>
                    <li className="list-inside list-disc">
                        Forming human resources who are honest, full of loyalty and sociable so as to create a conducive work environment
                    </li>
                    <li className="list-inside list-disc">
                        Creating new jobs so that they can provide benefits and blessings to others
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default VisiMisiSection