import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from '../assets/Images/Logo.png'
import { faBars, faClose } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import NavLink from './navlink'
const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false)

    const handleOpenMenu = () => {
        if (openMenu === true) {
            setOpenMenu(false)
        } else {
            setOpenMenu(true)
        }
    }

    const scrollToID = (event) => {
        event.preventDefault();

        const targetId = event.target.getAttribute("href").substring(1);
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
            targetElement.scrollIntoView({
                behavior: "smooth",
            });
        }
    };

    return (
        <nav className="w-full fixed md:sticky top-0  drop-shadow-md bg-white/70 backdrop-blur-sm z-50">
            <div className="w-full h-16 flex items-center justify-between px-5 md:px-10">
                <div>
                    <img src={Logo} alt="Logo Cokusi" className='h-10 aspect-square object-contain' />
                </div>
                <p className='text-xl font-semibold uppercase md:hidden'>Cokusi Kameumeut</p>
                <div className='hidden md:flex space-x-3 items-center'>
                    <NavLink name="Home" scrol={scrollToID} link="#welcomeSection" />
                    <NavLink name="Visi & Misi" scrol={scrollToID} link="#visiMisi" />
                    <NavLink name="About Us" scrol={scrollToID} link="#aboutUs" />
                    <NavLink name="Project Summery" scrol={scrollToID} link="#projectSummery" />
                    <NavLink name="Our Team" scrol={scrollToID} link="#ourTeam" />
                </div>
                <div>
                    <a href="#footer" onClick={scrollToID} className='hidden md:block font-semibold border-b-2 border-transparent hover:border-black transition-all duration-500'>Contact Us</a>
                    <button type='button' onClick={handleOpenMenu} className='md:hidden h-8 aspect-square flex items-center justify-center relative'>
                        <FontAwesomeIcon icon={faBars} className={`h-full aspect-square object-contain transition-all duration-500 ${openMenu === true ? 'scale-0 rotate-180' : 'scale-100 rotate-0'}`}></FontAwesomeIcon>
                        <FontAwesomeIcon icon={faClose} className={`h-full absolute text-red-500 transition-all duration-500 ${openMenu === true ? 'scale-100 rotate-0' : '-rotate-180 scale-0'}`}></FontAwesomeIcon>
                    </button>
                </div>
            </div>
            <div className={`w-full overflow-hidden md:hidden transition-all duration-500 flex flex-col items-center  px-3  ${openMenu === true ? 'h-64 border-t-2 py-5 space-y-3 ' : 'h-0 '}`}>
                <NavLink name="Home" scrol={scrollToID} link="#welcomeSection" />
                <NavLink name="Visi & Misi" scrol={scrollToID} link="#visiMisi" />
                <NavLink name="About Us" scrol={scrollToID} link="#aboutUs" />
                <NavLink name="Project Summery" scrol={scrollToID} link="#projectSummery" />
                <NavLink name="Our Team" scrol={scrollToID} link="#ourTeam" />
                <NavLink name="Contact Us" scrol={scrollToID} link="#footer" />
            </div>
        </nav>
    )
}
export default Navbar
