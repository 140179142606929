import TitleSection from "../../../components/title_section"
import Image4 from '../../../assets/Images/img4.jpg'
import { motion } from "framer-motion"

const AboutSection = () => {
    return (
        <div id="aboutUs" className="w-full h-fit lg:h-screen bg-white py-20">
            <TitleSection title="About Us" subTitle="Lorem ipsum dolor sit amet, consectetur adipisicing elit." />
            <div className="flex flex-col lg:flex-row  items-center md:space-x-5 px-3 md:px-10 pt-5 space-y-3 lg:space-y-0">
                <div className="w-full lg:w-1/2">
                    <motion.img
                        initial={{ opacity: 0, scale: 0, rotate: -260 }}
                        whileInView={{ opacity: 1, scale: 1, rotate: 0 }}
                        transition={{ duration: 1 }}
                        src={Image4} alt=" cokusi" className="w-[70%] md:w-[50%] lg:[70%] aspect-square object-contain rounded-2xl mx-auto" />
                </div>
                <div className="w-full lg:w-1/2">
                    <p className="text-center  text-xs md:text-sm lg:text-base">
                        CV. Dzikra Indonesia operates in the field of culinary food and snack processing, which wants to cultivate local food but packaged in a modern way and can be enjoyed by all groups and ages in Indonesia and the world.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default AboutSection