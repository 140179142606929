import Picture5 from '../../../assets/Images/Picture5.jpg'
import Picture7 from '../../../assets/Images/Picture7.jpg'
import Logo from '../../../assets/Images/Logo.png'
import BgKurma from '../../../assets/Images/bg-kurm.jpg'
import { motion } from 'framer-motion'
const WelcomeSection = () => {
    return (
        <motion.div
            id='welcomeSection'
            className="h-screen w-full  bg-white md:-mt-16 pt-16 flex items-center justify-center  bg-fixed bg-cover bg-bottom" style={{ backgroundImage: `url(${BgKurma})` }}>
            <div className="w-[90%] h-[90%] md:w-[80%] md:h-[80%] bg-white/50 backdrop-blur-sm rounded-3xl flex flex-col lg:flex-row  items-center justify-center space-y-3 lg:space-y-0">
                <div className='w-full md:w-1/2 flex flex-col items-center justify-center'>
                    <motion.img
                        initial={{ opacity: 0, scale:0 }}
                        transition={{ duration: 1 }}
                        whileInView={{ opacity: 1, scale:1 }} src={Logo} alt="Logo Coskusi" className='w-32 aspect-square mb-5' />

                    <div className='flex items-end space-x-5'>
                        <motion.img
                            initial={{ scaleX: 0, }}
                            transition={{ duration: 2 }}
                            whileInView={{ scaleX: 1, opacity: 1 }}
                            src={Picture5} alt="Coskusi" className='w-32 md:w-44 aspect-square object-cover rounded-full' />

                        <motion.img
                            initial={{ scaleX: 0, }}
                            transition={{ duration: 2 }}
                            whileInView={{ scaleX: 1, opacity: 1 }}
                            src={Picture7} alt="Coskusi" className='w-32 md:w-44 aspect-square object-cover rounded-full' />
                    </div>
                </div>
                <div className='w-full lg:w-1/2 space-y-3 pr-5 pl-5 lg:pl-0 lg:pr-10'>
                    <p className='text-center  text-red-700 text-3xl md:text-5xl font-semibold'>COKUSI KAMEUMEUT</p>
                    <p className='text-center font-semibold text-sm md:text-base'>
                        Cokusi kameumeut is present as a medium for da'wah through dates, a food recommended by Rasulullah's sunnah that can be enjoyed by people in a modern way.
                    </p>
                </div>
            </div>
        </motion.div>
    )
}

export default WelcomeSection