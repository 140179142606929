import TitleSection from "../../../components/title_section"
import { motion } from "framer-motion"
import Revenue from '../../../assets/Images/revenue.png'
import Picture8 from '../../../assets/Images/Picture8.png'

const BusinesModel = () => {
    return (
        <div id="businessModel" className="w-full h-screen bg-red-700 pt-20">
            <TitleSection title="Business Model" color="white" subTitle="Lorem ipsum dolor sit amet, consectetur adipisicing elit." />
            <div className="w-full flex flex-col lg:flex-row items-center justify-center h-full -mt-20 space-y-5 lg:space-y-0">
                <motion.div
                    initial={{ scaleX: 0, opacity: 0 }}
                    transition={{ duration: 1 }}
                    whileInView={{ scaleX: 1, opacity: 1 }}
                    className="w-40 md:w-60 aspect-square bg-white rounded-full flex items-center justify-center uppercase text-black font-bold text-center stroke-black border-2 border-black">
                    Business <br></br> Model
                </motion.div>
                <div className="space-y-6 flex flex-col">
                    <div className="popover popover-hover">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            transition={{ duration: 1, delay: 1 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            className="popover-trigger w-72 md:w-96 lg:w-72 bg-white rounded-full flex items-center justify-center uppercase font-semibold py-1 md:py-2 border-2 border-black">
                            Product
                        </motion.div>
                        <div className="popover-content bg-white w-56 h-fit">
                            <img src={Picture8} alt="cokusi revenue" />
                        </div>
                    </div>

                    <div className="popover popover-hover">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            transition={{ duration: 1, delay: 1 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            className="popover-trigger w-72  md:w-96 lg:w-72 lg:ml-8 bg-white rounded-full flex items-center justify-center uppercase font-semibold py-1 md:py-2 border-2 border-black">
                            Distribution
                        </motion.div>
                        <div className="popover-content bg-white w-56 h-fit">
                            <ul className="ml-5 text-sm">
                                <li className="list-outside list-disc uppercase font-semibold text-sm">Super Market</li>
                                <li className="list-outside list-disc uppercase font-semibold">Mini Market</li>
                                <li className="list-outside list-disc uppercase font-semibold">E-commerce</li>
                                <li className="list-outside list-disc uppercase font-semibold">Sosial media platform</li>
                            </ul>
                        </div>
                    </div>

                    <div className="popover popover-hover">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            transition={{ duration: 1, delay: 1 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            className="popover-trigger w-72 md:w-96 lg:w-72  lg:ml-8 bg-white rounded-full flex items-center justify-center uppercase font-semibold py-1 md:py-2 border-2 border-black">
                            Service
                        </motion.div>
                        <div className="popover-content bg-white w-56 h-fit">
                            <ul className="ml-5 text-sm">
                                <li className="list-outside list-disc uppercase font-semibold text-sm">Delivery with courier</li>
                                <li className="list-outside list-disc uppercase font-semibold">quality assurance</li>
                                <li className="list-outside list-disc uppercase font-semibold">discount spesial</li>
                                <li className="list-outside list-disc uppercase font-semibold">give away</li>
                            </ul>
                        </div>
                    </div>

                    <div className="popover popover-hover">
                        <motion.div
                            initial={{ x: -100, opacity: 0 }}
                            transition={{ duration: 1, delay: 1 }}
                            whileInView={{ x: 0, opacity: 1 }}
                            className="popover-trigger w-72 md:w-96 lg:w-72  bg-white rounded-full flex items-center justify-center uppercase font-semibold py-1 md:py-2 border-2 border-black " >
                            Direct Selling / Gift Shop
                        </motion.div>
                        <div className="popover-content bg-white w-32 aspect-square h-fit">
                            <img src={Revenue} alt="cokusi revenue" />
                        </div>
                    </div>
                </div>

            </div >
        </div >
    )
}

export default BusinesModel